import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useApp } from '../contexts/AppContext'

import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'


const Header = () => {
  const { dataRefund } = useApp()
  const { t } = useTranslation()

  /**
   * 
   * @returns 
   */
  const Logo = () => {
    if (dataRefund.state === 'awaiting-request') {
      return <Skeleton className="skeleton-logo" style={{ borderRadius: 4 }} variant="rect" />
    }

    return (
      <div className="logo">
        {dataRefund.state === 'not-found'
          ? <img src="./new_logo.png" alt="WePayments" />
          : <img alt={dataRefund.data.merchant.name} src={`https://assets.goboleto.com/${dataRefund.data.merchant.logo}`} />}
      </div>
    )
  }

  /**
   * 
   * @returns
   */
  const ProcessorLogo = () => {
    if (['awaiting-request','not-found'].includes(dataRefund.state)) return false


    return (
      <Processor>
        <div className="processor-tag">{t('processed_by')}</div>
        <div className="processor-image"><img src="./new_logo.png" alt="WePayments" /></div>
      </Processor>
    )
  }

  const Title = () => {
    if (dataRefund.state === 'awaiting-request') return false

    if (dataRefund.state === 'not-found') {
      return (
        <TitleWrapper>
          <Grid container spacing={3}>
            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }} item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography style={{ lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: t('not_found_refund') }} />
            </Grid>
          </Grid>
        </TitleWrapper>
      )
    }

    return (
      <TitleWrapper>
        <Grid container spacing={3}>
          <Grid style={{ display: 'flex', alignItems: 'center' }} item sm={4} md={4} lg={3} xl={3}>
            <TitleText>{t('refund_title')}</TitleText>
          </Grid>
          {[1,5].includes(dataRefund.data.status) && (
            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }} item sm={8} md={8} lg={8} xl={8}>
              <Typography style={{ lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: t('enunciated_refund') }} />
              <Typography style={{ lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: t('enunciated_refund_steps', { merchant_name: dataRefund.data.merchant.name, secondary_color: dataRefund.data.merchant.secondary_color }) }} />
            </Grid>
          )}
        </Grid>
      </TitleWrapper>
    )
  }

  const ButtonBack = () => {
    return (
      <>
        <WrapperBack 
          onClick={() => {
            if (['validation', 'sending-data'].includes(dataRefund.formState)) return true

            document.querySelector('#back-step').click()
            window.scrollTo(0,0)
          }}
          className={dataRefund.stateWizardMobile.activeStep !== 1 ? 'show' : undefined}>
          <ArrowBackIosIcon />
        </WrapperBack>
      </>
    )
  }

  return (
    <HeaderWrapper>
      <Container>
        <GridHeader>
          <MainHeader>
            <ButtonBack />
            <Logo />
          </MainHeader>
          <ProcessorLogo />
        </GridHeader>
        <Title />
      </Container>
    </HeaderWrapper>
  )
}

const WrapperBack = styled.div`
  width: 68px;
  height: 68px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -68px;
  transition: all 0.3s;
  cursor: pointer;

  &.show {
    margin-left: -16px;
    transition: all 0.3s;
  }

  @media(min-width: 960px) {
    display: none;
  }
`

const TitleWrapper = styled.div`
  display: none;
  min-height: 100px;
  align-items: center;
  padding: 15px 0 30px;

  @media(min-width: 960px) {
    display: flex;
  }
`

const TitleText = styled.h1`
  margin: 0;
  font-size: 34px;
  font-weight: 900;
  line-height: 1;
`

const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const HeaderWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 68px;
  position: fixed;
  background: #fff;
  /* margin-bottom: 11px; */
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);

  // container
  > div { height: 68px; }

  @media(min-width: 960px) {
    height: auto;
    position: relative;

    // container
    > div { height:auto; }
  }
`

const MainHeader = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  .skeleton-logo {
    width: 80px;
    height: 38px;
  }
  
  .logo {
    display: flex;
    
    img {
      height: 38px;
    }
  }

  @media(min-width: 960px) {
    padding: 28px 0;

    .skeleton-logo {
      width: 120px;
      height: 49px;
    }

    .logo {
      img {
        height: 49px;
      }
    }
  }
`

const Processor = styled.div`
  display: flex;
  flex-direction: column;

  .processor {
    &-tag {
      color: #999999;
      font-size: 10px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    &-image {
      display: flex;
      justify-content: flex-end;

      img {
        height: 27px;
      }
    }
  }  

  @media(min-width: 960px) {
    flex-direction: row;
    align-items: center;

    .processor-tag {
      margin-bottom: 0;
      margin-right: 15px;
      font-size: 12px;
    }

    .processor-image img {
      height: 41px;
    }
  }
`

export default Header