import React from 'react'
import styled from 'styled-components'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const BranchAccount = props => {
  const { title } = props
  const { t } = useTranslation()
  const methods = useFormContext()

  return (
    <Wrapper {...props}>
      <FormControl fullWidth error={Boolean(methods.errors.branch_account)} component="fieldset">
        {title && <TitleForm style={{ marginBottom: 20 }} className={Boolean(methods.errors.branch_account) ? 'has-error' : undefined}>{title}</TitleForm>}

        <Controller
          name="branch_account" 
          control={methods.control}
          defaultValue=""
          render={({ ...props }) => (
            <TextField
              {...props}
              disabled={methods.formState.isSubmitting}
              type={'number'}
              label={props.mobile ? '' : t('branch_account.title')}
              onInput={e=> e.target.value = e.target.value.toString().slice(0,4)}
              min={0}
              placeholder={t('branch_account.placeholder')}
              error={Boolean(methods.errors.branch_account)}
              variant={props.mobile ? 'filled' : 'outlined'}
              id="branch_account"
              helperText={methods.errors.branch_account ? (methods.errors.branch_account.message) : ''}
              autoComplete="off"
            />
          )}
        />

      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media(min-width: 960px) {
    margin-bottom: 30px;

    .wrapper-radio-group {
      flex-direction: row;

      > label {
        margin-right: 30px;
      }
    }
  }
`

const TitleForm = styled.h3`
  margin-top: 0;
  margin-bottom: 7px;

  &.has-error {
    color: #ff1744;
  }
`

export default BranchAccount