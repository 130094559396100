import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import Section from '../components/Section'
import IconInformation from '../components/IconInformation'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useApp} from "../contexts/AppContext";

const HelpDataBanking = props => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()
  const { getValues } = useFormContext()

  if (getValues().bank?.code === '104') {
    return (
      <a 
        style={{ textDecoration: 'none' }} 
        href="https://ajuda.wepayout.com.br/tenho-uma-conta-poupança-na-caixa-econômica-federal-como-devo-informar-meus-dados-bancários-para-recebimento-do-reembolso" 
        target="_blank">
        <Section 
          {...props}
          style={{
            backgroundColor: '#fbd08f',
            boxShadow: 'none'
          }}>
          <Grid container spacing={3}>
            <Grid 
              item
              xs={'auto'}
              style={{ display: 'flex', alignItems: 'center' }}>
              <IconInformation negative={true} pulse={true} />
            </Grid>
            <Grid item xs>
              <img src="caixa.png" alt="Caixa" style={{ height: 20, marginBottom: 5 }} />
              <Typography 
                style={{ lineHeight: 1.2 }} 
                dangerouslySetInnerHTML={{ __html: t('help_data_banking_caixa_text_helcenterredirect', { secondary_color: '#444' }) }}
              />
            </Grid>
          </Grid>
        </Section>
      </a>
    )
  }

  return (
    <a 
      style={{ textDecoration: 'none' }} 
      href="https://ajuda.wepayout.com.br/como-preencher-meus-dados-no-formulario-de-reembolso" 
      target="_blank">
      <Section {...props}>
        <Grid container spacing={3}>
          <Grid 
            item
            xs={'auto'}
            style={{ display: 'flex', alignItems: 'center' }}>
            <IconInformation />
          </Grid>
          <Grid item xs>
            <Typography 
              style={{ lineHeight: 1.2, marginBottom: 5 }} 
              dangerouslySetInnerHTML={{ __html: t('help_data_banking_text') }}
            />
            <Typography 
              style={{ lineHeight: 1.2 }} 
              dangerouslySetInnerHTML={{ __html: t('help_data_banking_text_helcenterredirect', { secondary_color: dataRefund.data.merchant.secondary_color }) }}
            />
          </Grid>
        </Grid>
      </Section>
    </a>
  )
}

export default HelpDataBanking