import React from 'react'
import styled from 'styled-components'
import { Helmet } from "react-helmet"
import { useApp } from './contexts/AppContext'
import { useTranslation } from "react-i18next"
import { FormWizardProvider } from './contexts/FormContext'

import Hidden from "@material-ui/core/Hidden"
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

import LayerAlert from './components/LayerAlert'

import Mobile from './areas/Mobile'
import Desktop from './areas/Desktop'
import Section from "./components/Section"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ContentApp = () => {
  const { dataRefund, setDataRefund } = useApp()
  const { t } = useTranslation()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setDataRefund(prevState => {
      return {
        ...prevState,
        error: null
      }
    })
  }

  const title = () => {
    if (dataRefund.state === 'not-found') {
      return `Coleta de dados bancários não encontrada`
    }

    return `Coleta de dados bancários de ${dataRefund.data.beneficiary.name}`
  }

  const description = () => {
    if (dataRefund.state === 'not-found') {
      return `Coleta de dados bancários não encontrada`
    }

    return `Você tem um pagamento aprovado de ${dataRefund.data.amount}. Informe seus dados bancários para receber.`
  }

  const color = () => {
    if (dataRefund.state === 'not-found') {
      return `#000`
    }

    return dataRefund.data.merchant.primary_color
  }

  if (dataRefund.state === 'awaiting-request') {
    return <LayerAlert message="Carregando" loading={true} />
  }

  if ([3].includes(dataRefund.data.status)) {
    return <LayerAlert message="Coleta de dados bancários cancelada" />
  }  

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title()}</title>
        <meta name="description" content={description()} />
        <meta name="theme-color" content={color()} />
      </Helmet>
      <Snackbar open={Boolean(dataRefund.error)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} style={{ marginBottom: 80 }} severity="error">
          {dataRefund.error}
        </Alert>
      </Snackbar>
      {dataRefund.state !== 'not-found'
        ? (
            <FormWizardProvider>
              <Mobile />
              <Desktop />
            </FormWizardProvider>
          )
        : (
            <Hidden only={['md', 'xl', 'lg']}>
              <SpaceEmptyTop />
              <Section>
                {t('not_found_refund')}
              </Section>
            </Hidden>
          )}
    </Wrapper>
  )
}

const SpaceEmptyTop = styled.div`
  height: 11px;
`

const Wrapper = styled.div`
  margin-top: calc(68px);
  height: calc(100vh - (68px));

  form {
    height: 100%;
  }

  .rsw_2Y, .rsw_2Y > div  {
    height: 100%;
  }

  @media(min-width: 960px) { 
    margin-top: 0; 

    form {
      height: auto;
    }
  }

`

export default ContentApp
