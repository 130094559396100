import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import Section from '../components/Section'
import WhyWePayOut from '../partials/WhyWePayOut'
import PaymentInfo from '../partials/PaymentInfo'
import DocumentRefund from '../partials/DocumentRefund'
import HelpDataBanking from '../partials/HelpDataBanking'

import TypeTransactionRefund from '../forms/TypeTransactionRefund'
import TypePixKey from '../forms/TypePixKey'
import ValuePixKey from '../forms/ValuePixKey'
import Bank from '../forms/Bank'
import TypeAccount from '../forms/TypeAccount'
import BranchAccount from '../forms/BranchAccount'
import Account from '../forms/Account'
import AccountDigit from '../forms/AccountDigit'
import { useFormContext } from 'react-hook-form'
import RefundStatus from "../partials/RefundStatus";
import {useApp} from "../contexts/AppContext";
import ShowError from "../partials/ShowError";
import ConfirmData from '../partials/ConfirmData'

const Desktop = () => {
  const { t } = useTranslation()
  const { getValues, errors } = useFormContext()
  const { dataRefund } = useApp()

  const enableTypePixKey = () => {
    const values = getValues()
    return Boolean(values.type) && values.type === 'pix'
  }

  const enableValuePixKey = () => {
    const values = getValues()
    return Boolean(values.type_pix_key)
  }

  const enableDataBanking = () => {
    const values = getValues()
    return Boolean(values.type) && values.type === 'databanking'
  }

  if ([2,4].includes(dataRefund.data.status)) {
    return (
      <Hidden only={['xs', 'sm']}>
        <Container style={{ marginTop: 30 }}>
          <Grid container spacing={5}>
            <Grid item md={8}>
              <RefundStatus />
            </Grid>
            <Grid item md={4}>
              <PaymentInfo />
            </Grid>
          </Grid>
        </Container>
      </Hidden>
    )
  }

  return (
    <Hidden only={['xs', 'sm']}>
      <Container style={{ marginTop: 30 }}>
        <Grid container spacing={5}>
          <Grid item md={8}>
            <Grid container spacing={2}>


              {['confirm-data','sending-data'].includes(dataRefund.formState)
                ? (<>
                    <Grid item md={12}>
                      <ConfirmData />
                    </Grid>
                  </>)

                : (<>
                    {dataRefund.data.status === 5 && (
                      <Grid item md={12}>
                        <ShowError />
                      </Grid>
                    )}
                    <Grid item md={12}>
                      <Section title={t('info_refund')}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                          <TypeTransactionRefund title={t('type_refund_payment.title')} />
                          {enableTypePixKey() && <TypePixKey title={t('type_pix_key.title')} />}
                          {enableValuePixKey() && <ValuePixKey title={t('value_pix_key.title')} />}
                          {enableDataBanking() && <React.Fragment>
                            <Bank title={t('bank.title')} />
                            <TypeAccount title={t('type_account.title')} />

                            <Grid container spacing={3}>
                              <Grid item xs={6}><BranchAccount style={{ marginBottom: 0 }} title={t('data_account')} /></Grid>
                              <Grid item xs={6}></Grid>
                              <Grid item xs={6}><Account /></Grid>
                              <Grid item xs={6}><AccountDigit /></Grid>
                              <Grid item xs={12}><HelpDataBanking style={{ cursor: 'pointer', backgroundColor: '#eee', padding: '20px 30px', boxShadow: 'none', marginBottom: 20 }} /></Grid>
                            </Grid>

                            
                          </React.Fragment>}

                          <Button type="submit" style={{ marginTop: 10 }} size="large" variant="contained" color="primary">
                            {t('continue')}
                          </Button>
                        </div>
                      </Section>
                    </Grid>
                  </>)}

              
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PaymentInfo />
              </Grid>
              <Grid item xs={12}>
                <DocumentRefund />
              </Grid>
              <Grid item xs={12}>
                <WhyWePayOut />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Hidden>
  )
}

export default Desktop