import React from 'react'
import styled from 'styled-components'
import { useApp } from "../contexts/AppContext"
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import TextField from "@material-ui/core/TextField"
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from "@material-ui/lab/Autocomplete"

const Bank = props => {
  const { title } = props
  const { t } = useTranslation()
  const { dataRefund } = useApp()
  const methods = useFormContext()
  const principalsBanks = ['001','104','341','260','077','237','033']

  const banks = React.useMemo(() => {
    return dataRefund.banks.sort((a, b) => {
      return principalsBanks.includes(a.code) ? -1 : 1
    })
  }, [])

  return (
    <Wrapper>
      <FormControl fullWidth error={Boolean(methods.errors.bank)} component="fieldset">
        {title && <TitleForm style={{ marginBottom: 20 }} className={Boolean(methods.errors.bank) ? 'has-error' : undefined}>{title}</TitleForm>}

        <Controller
          name="bank"
          control={methods.control}
          defaultValue={null}
          onChange={([, obj]) => obj}
          render={({ onChange, ...props }) => (
            <Autocomplete
              options={banks}
              disabled={methods.formState.isSubmitting}
              onChange={(e, value) => { 
                onChange(value)
                methods.trigger() 
              }}
              getOptionLabel={option => option ? option.name : ''}
              groupBy={option => principalsBanks.includes(option.code) ? t('bank.principal_banks') : t('bank.others_banks')}
              renderInput={params => (
                <TextField
                  {...params}
                  name={'bank'}
                  label={t('bank.placeholder')}
                  placeholder={t('bank.placeholder')}
                  error={Boolean(methods.errors.bank)}
                  variant={Boolean(props.mobile) ? 'filled' : 'outlined'}
                  helperText={methods.errors.bank ? (methods.errors.bank.message) : ''}
                  autoComplete="off"
                />
              )}
              {...props}
            />
          )}
        />

      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  
  @media(min-width: 960px) {
    margin-bottom: 30px;

    .wrapper-radio-group {
      flex-direction: row;

      > label {
        margin-right: 30px;
      }
    }
  }
`

const TitleForm = styled.h3`
  margin-top: 0;
  margin-bottom: 7px;

  &.has-error {
    color: #ff1744;
  }
`

export default Bank