// import React from 'react'
import React, { Suspense } from 'react'
import axios from "axios"
import { useApp } from './contexts/AppContext'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Header from './components/Header'
import ContentApp from './ContentApp'

import CssBaseline from '@material-ui/core/CssBaseline'
import CircularProgress from '@material-ui/core/CircularProgress'
import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'

const PRIMARY_COLOR_DEFAULT = '#0098f2'
const SECONDARY_COLOR_DEFAULT = '#000'

function Page({ match: { params: { hash } } }) {
  const { setDataRefund, dataRefund } = useApp()

  const getData = React.useCallback(() => {
    const refund = new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/payout/refunds/hash/${hash}`)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })

    const banks = new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/payout/refund-banks`)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })

    Promise
      .all([refund, banks])
      .then((result) => {
        const [refund, banks] = result
        const [primary_color, secondary_color] = refund.merchant.primary_color
          ? refund.merchant.primary_color.split('|') 
          : `${PRIMARY_COLOR_DEFAULT}|${SECONDARY_COLOR_DEFAULT}`.split('|')

        setDataRefund(v => {
          return {
            ...v,
            state: 'done',
            banks,
            hash,
            data: {
              ...refund,
              merchant: {
                ...refund.merchant,
                primary_color: primary_color ? primary_color : PRIMARY_COLOR_DEFAULT,
                secondary_color: secondary_color ? secondary_color : SECONDARY_COLOR_DEFAULT
              }
            }
          }
        })
      })
      .catch(() => {
        setDataRefund(v => {
          return {
            ...v,
            state: 'not-found'
          }
        })
      })
  },[hash])

  React.useEffect(() => {
    getData()
  }, [getData])

  const theme = React.useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: {
          main: `${dataRefund.state === 'done' ? dataRefund.data.merchant.primary_color : '#0099f5'}`,
        },
        secondary: {
          main: `${dataRefund.state === 'done' ? dataRefund.data.merchant.secondary_color : '#0099f5'}`,
        },
        error: {
          main: red.A400,
        },
        background: {
          default: '#EEEEEE',
        },
      },
      typography: {
        allVariants: {
          color: '#444'
        }
      }
    })
  }, [dataRefund])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Header />
        <ContentApp />
      </div>
    </ThemeProvider>
  )
}

export default function App() {
  return (
    <Router>
      <Suspense 
        fallback={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="inherit" />
          </div>
        }>
        <Switch>
          <Route exact path="/:hash" component={Page} />
        </Switch>
      </Suspense>
    </Router>
  )
}