import React from 'react'
import axios from "axios";

const AppContext = React.createContext({})

const AppProvider = props => {
  const [dataRefund, setDataRefund] = React.useState({
    state: 'awaiting-request',
    data: {},
    banks: [],
    confirmData: null,
    error: null,
    formState: 'initial',
    stateWizardMobile: {
      previousStep: 0,
      activeStep: 1,
    }
  })

  const sendingData = React.useCallback(() => {
    const values = dataRefund.confirmData
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/payout/refunds/hash/${dataRefund.hash}`, {
        ...(values.type === 'pix' && {
          pix_key: values.type_pix_key !== 'tax_id' ? (values.type_pix_key === 'phone' ? `+55${values.value_pix_key}` : values.value_pix_key) : '',
          pix_key_type: values.type_pix_key,
        }),
        ...(values.type === 'databanking' && {
          branch: values.branch_account,
          account: values.account,
          account_digit: values.account_digit,
          type: values.type_account,
          bank: values.bank.code
        })
      })
      .then(({ data }) => {
        const [primary_color, secondary_color] = data.merchant.primary_color.split('|')

        setDataRefund(prevState => {
          return {
            ...prevState,
            data: {
              ...data,
              merchant: {
                ...data.merchant,
                primary_color,
                secondary_color
              }
            },
            formState: 'initial',
            confirmData: null,
            stateWizardMobile: {
              previousStep: 0,
              activeStep: 1,
            }
          }
        })
      })
      .catch(error => {
        setDataRefund(prevState => {
          return {
            ...prevState,
            formState: 'confirm-data',
            error: error.response.data.message
          }
        })
      })
      .finally(() => console.log('finalizou'))
  }, [dataRefund.confirmData])

  React.useEffect(() => {
    if (dataRefund.formState === 'sending-data') {
      sendingData()
    }
  }, [dataRefund.formState, sendingData])

  return (
    <AppContext.Provider 
      value={{
        dataRefund, 
        setDataRefund
      }}>
      {props.children}
    </AppContext.Provider>
  )
}

function useApp() {
  const context = React.useContext(AppContext)

  if (!context) {
    throw new Error('useApp must be used within an AppProvider.');
  }

  return context;
}

export {
  AppProvider,
  useApp
}