import React from 'react'
import styled from 'styled-components'

import InfoIcon from '@material-ui/icons/Info'

const IconInformation = ({ pulse = false, negative = false }) => {
  return (
    <Icon className={`${negative && 'negative'}`} pulse={pulse}>
      <InfoIcon style={{ fontSize: 28 }} />
    </Icon>
  )
}

const Icon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 26px;

  &.negative {
    svg {
      color: #fff !important;
    }

    &::after {
      background: #bd965b !important;
    }
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    z-index: 2;
  }

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    z-index: 1;
    position: absolute;
    border-radius: 50%;
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(244, 196, 123, 1);
    background: ${props => props.pulse ? '#F4C47B' : '#AAAAAA'};
    ${props => props.pulse && `animation: pulse 2s infinite;`}
  }

  svg {
    color: ${props => props.pulse ? '#F4C47B' : '#AAAAAA'};
    z-index: 3;
    position: relative;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(128, 96, 48, 0.3);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(244, 196, 123, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(244, 196, 123, 0);
    }
  }
`

export default IconInformation