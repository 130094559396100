import React from 'react'
import { useApp } from "../contexts/AppContext"
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'

import Typography from '@material-ui/core/Typography'

const ShowError = props => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()

  return (
    <Section
      {...(dataRefund.data.status === 5 && {
        style: {
          backgroundColor: '#FFF5F5'
        },
        styleTitle: {
          color: '#B31C1C',
          ...(!props.mobile && {
            backgroundColor: '#FFCECE'
          })
        }
      })}
      title={t('error_view.title')}>
      <Typography
        style={{ lineHeight: 1.2, marginBottom: 10, color: '#641414'  }}
        dangerouslySetInnerHTML={{ __html: t('error_view.text1') }}
      />
      <Typography
        style={{ lineHeight: 1.2, color: '#641414' }}
        dangerouslySetInnerHTML={{ __html: t('error_view.text2') }}
      />
    </Section>
  )
}

export default ShowError