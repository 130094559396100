import React from 'react'
import { useApp } from "../contexts/AppContext"
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'

import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import Grid from "@material-ui/core/Grid"

const RefundStatus = props => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()

  const CardStatus = () => (
    <Section
      {...(dataRefund.data.status === 4 && {
        style: {
          backgroundColor: '#F8FFF5'
        },
        styleTitle: {
          color: '#44AC46',
          ...(!props.mobile && {
            backgroundColor: '#DFF9D4'
          })
        }
      })}
      title={dataRefund.data.status === 4 ? t('refund_status.title_done') : t('refund_status.title_processing')}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={'auto'}><CheckIcon style={{ color: '#61B733', fontSize: 28 }} /></Grid>
            <Grid item xs={true}>
              <Typography
                style={{ lineHeight: 1.2 }}
                dangerouslySetInnerHTML={{ __html: t('refund_status.success_sending_data') }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {dataRefund.data.status === 4
            ? (
              <Grid container spacing={2}>
                <Grid item xs={'auto'}><CheckIcon style={{ color: '#61B733', fontSize: 28 }} /></Grid>
                <Grid item xs={true}>
                  <Typography
                    style={{ lineHeight: 1.2 }}
                    dangerouslySetInnerHTML={{ __html: t('refund_status.success_processing') }}
                  />
                </Grid>
              </Grid>
              )
            : (
              <Grid container spacing={2}>
                <Grid item xs={'auto'}><TimelapseIcon style={{ color: '#019FFC', fontSize: 28 }} /></Grid>
                <Grid item xs={true}>
                  <Typography
                    style={{ lineHeight: 1.2, marginBottom: 10  }}
                    dangerouslySetInnerHTML={{ __html: t('refund_status.awaiting_processing') }}
                  />
                  <Typography
                    style={{ lineHeight: 1.2}}
                    dangerouslySetInnerHTML={{ __html: t('refund_status.awaiting_processing_help', { secondary_color: dataRefund.data.merchant.secondary_color }) }}
                  />
                </Grid>
              </Grid>
              )}
        </Grid>
      </Grid>
    </Section>
  )

  if (dataRefund.data.status === 4) {
    return <CardStatus />
  }
  
  return (
    <a 
      style={{ textDecoration: 'none' }} 
      href="https://ajuda.wepayout.com.br/informei-meus-dados-no-formulário-recebido.-qual-o-prazo-para-receber-meu-reembolso" 
      target="_blank">
      <CardStatus />
    </a>
  )
}

export default RefundStatus