import React from 'react'
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ShowInfo from '../components/ShowInfo'
import {useApp} from "../contexts/AppContext";

const PaymentInfo = () => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()
  
  return (
    <Section title={t('payment_info.title')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ShowInfo
            label={t('payment_info.fullname')}
            value={dataRefund.data.beneficiary.name}
          />
        </Grid>
        <Grid item xs={12}>
          <ShowInfo
            label={t('payment_info.email')}
            value={dataRefund.data.beneficiary.email}
          />
        </Grid>
        <Grid item xs={12}>
          <ShowInfo
            label={t('payment_info.amount')}
            value={dataRefund.data.amount}
          />
        </Grid>
      </Grid>
    </Section>
  )
}

export default PaymentInfo