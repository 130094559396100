import React from 'react'
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'
import IconInformation from '../components/IconInformation'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useApp} from "../contexts/AppContext";

const DocumentRefund = () => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()
  
  return (
    <Section 
      style={{
        backgroundColor: '#fbd08f',
        boxShadow: 'none'
      }}>
      <Grid container spacing={3}>
        <Grid 
          item
          xs={'auto'}
          style={{ display: 'flex', alignItems: 'center' }}>
          <IconInformation negative={true} pulse={true} />
        </Grid>
        <Grid item xs>
          <Typography 
            style={{ lineHeight: 1.2, marginBottom: 5 }} 
            dangerouslySetInnerHTML={{ __html: t('document_refund') }}
            />
          <Typography style={{ lineHeight: 1.2 }}>
            {dataRefund.data.beneficiary.document.type.toLocaleUpperCase()}: {dataRefund.data.beneficiary.document.value}
          </Typography>
        </Grid>
      </Grid>
    </Section>
  )
}

export default DocumentRefund