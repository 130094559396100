import React from 'react'
import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'

const LayerAlert = props => {
  const { message, loading } = props

  return (
    <Wrapper>
      {loading && <CircularProgress color="inherit" style={{ marginBottom: 30 }} />}
      {message}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: calc(100vh);
  padding-top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-direction: column;

  @media(min-width: 960px) {
    padding-top: 0;
    height: calc(100vh - (105px * 2));
    font-size: 24px;
  }
`

export default LayerAlert