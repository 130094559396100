import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const hash = new Date().getTime()

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      queryStringParams: { v: hash }
    }
  })

export default i18n