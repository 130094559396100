import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {useApp} from "./AppContext";
import axios from "axios";

const FormWizardProvider = props => {
  const { t } = useTranslation()
  const { setDataRefund, dataRefund } = useApp()

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(yup.object().shape({

      type: yup.string().nullable().required(t('validations.field_required')),
      type_pix_key: yup.string().nullable()
        .when('type', {
          is: type => type === 'pix',
          then: yup.string()
            .nullable()
            .required(t('validations.field_required'))
        }),
      value_pix_key: yup.string().nullable()
        .when(['type','type_pix_key'], {
          is: (type, type_pix) => type === 'pix' && type_pix !== 'tax_id',
          then: yup.string()
            .nullable()
            .required(t('validations.field_required'))
        }),
      bank: yup.object().nullable()
        .when('type', {
          is: type => type === 'databanking',
          then: yup.object()
            .nullable()
            .required(t('validations.field_required'))
        }),
      branch_account: yup.string()
        .when('type', {
          is: type => type === 'databanking',
          then: yup.string()
            .required(t('validations.field_required'))
        }),
      account: yup.string()
        .when('type', {
          is: type => type === 'databanking',
          then: yup.string()
            .required(t('validations.field_required'))
        }),
      account_digit: yup.string()
        .when('type', {
          is: type => type === 'databanking',
          then: yup.string()
            .required(t('validations.field_required'))
        }),
      type_account: yup.string().nullable()
        .when('type', {
          is: type => type === 'databanking',
          then: yup.string()
            .nullable()
            .required(t('validations.field_required'))
        })

    }))
  })

  /**
   * Validation banking data
   *
   * @param values
   * @returns {Promise<boolean>}
   */
  const invalidBankingData = async values => {
    return await new Promise(resolve => {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/payout/refunds/validate-banking-data`, {
          hash: dataRefund.hash,
          ...(values.type === 'pix' && {
            pix_key: values.type_pix_key === 'phone' ? `+55${values.value_pix_key}` : values.value_pix_key,
          }),
          ...(values.type === 'databanking' && {
            branch: values.branch_account,
            account: values.account,
            account_digit: values.account_digit,
            type: values.type_account,
            bank: values.bank.code
          })
        })
        .then(() => resolve(false))
        .catch(error =>  resolve(error.response.data))
    })
  }

  const onSubmit = async values => {

    if (values.type === 'pix' && values.type_pix_key === 'tax_id') {
      document.querySelector('#last-step') && document.querySelector('#last-step').click()
      window.scrollTo(0,0)

      setDataRefund(prevState => {
        return {
          ...prevState,
          formState: 'confirm-data',
          confirmData: values,
        }
      })

      return
    }
    
    setDataRefund(prevState => { return { ...prevState, formState: 'validation' } })
    const hasInvalidation = await invalidBankingData(values)

    if (hasInvalidation) {

      if (hasInvalidation.pix_key) {
        methods.setError("value_pix_key", {
          type: "manual",
          message: hasInvalidation.pix_key[0]
        })
      }

      if (hasInvalidation.account_digit) {
        methods.setError("account_digit", {
          type: "manual",
          message: hasInvalidation.account_digit[0]
        })
      }

      if (hasInvalidation.account) {
        methods.setError("account", {
          type: "manual",
          message: hasInvalidation.account[0]
        })
      }

      if (hasInvalidation.branch) {
        methods.setError("branch_account", {
          type: "manual",
          message: hasInvalidation.branch[0]
        })
      }

      if (hasInvalidation.bank) {
        methods.setError("bank", {
          type: "manual",
          message: hasInvalidation.bank[0]
        })
      }

      setDataRefund(prevState => { return { ...prevState, formState: 'initial' } })
      return
    }

    setDataRefund(prevState => {
      return {
        ...prevState,
        formState: 'confirm-data',
        confirmData: values,
      }
    })

    document.querySelector('#last-step') && document.querySelector('#last-step').click()
    window.scrollTo(0,0)

    return
  }


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {props.children}
      </form>
    </FormProvider>
  )
}

export {
  FormWizardProvider
}