import React from 'react'
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'

import Grid from '@material-ui/core/Grid'
import ShowInfo from '../components/ShowInfo'
import {useApp} from "../contexts/AppContext"
import Button from "@material-ui/core/Button"
import {CircularProgress} from "@material-ui/core";

const ConfirmData = props => {
  const { t } = useTranslation()
  const { dataRefund, setDataRefund } = useApp()

  return (
    <Section 
      {...(!props.mobile && {
        style: {
          backgroundColor: '#FFF9F0'
        },
        styleTitle: {
          color: '#000',
          ...(!props.mobile && {
            backgroundColor: '#FBD59D'
          })
        }
      })}
      title={t('confirm.title')}>
      {dataRefund.confirmData && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShowInfo
              label={t('payment_info.fullname')}
              value={dataRefund.data.beneficiary.name}
            />
          </Grid>
          <Grid item xs={12}>
            <ShowInfo
              label={t('payment_info.email')}
              value={dataRefund.data.beneficiary.email}
            />
          </Grid>
          <Grid item xs={12}>
            <ShowInfo
              label={t('confirm.document')}
              value={dataRefund.data.beneficiary.document.value}
            />
          </Grid>
          <Grid item xs={12}>
            <ShowInfo
              label={t('type_payment')}
              value={dataRefund.confirmData.type === 'pix' ? 'Chave Pix' : 'Dados bancários'}
            />
          </Grid>

          {dataRefund.confirmData.type === 'databanking' && <>
            <Grid item xs={12}>
              <ShowInfo
                label={t('bank.title')}
                value={dataRefund.confirmData.bank.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <ShowInfo
                    label={t('branch_account.title')}
                    value={dataRefund.confirmData.branch_account}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ShowInfo
                    label={t('type_account.title')}
                    value={dataRefund.confirmData.type_account === 's' ? 'Conta poupança' : 'Conta corrente'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ShowInfo
                    label={t('account.title')}
                    value={dataRefund.confirmData.account}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ShowInfo
                    label={t('account_digit.title')}
                    value={dataRefund.confirmData.account_digit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>}

          {dataRefund.confirmData.type === 'pix' && <>
            <Grid item xs={12}>
              <ShowInfo
                label={t('confirm.pix_key')}
                value={dataRefund.confirmData.type_pix_key === 'tax_id' ? dataRefund.data.beneficiary.document.value : dataRefund.confirmData.value_pix_key}
              />
            </Grid>
          </>}

          <Grid item xs={12}>
            <ShowInfo
              label={t('payment_info.amount')}
              value={dataRefund.data.amount}
            />
          </Grid>

          {!props.mobile && (<>
            <Grid item xs={'auto'} style={{ marginTop: 30 }}>
              <Button
                onClick={() => {
                  setDataRefund(prevState => {
                    return {
                      ...prevState,
                      formState: 'sending-data',
                    }
                  })
                }}
                disabled={dataRefund.formState === 'sending-data'}
                style={{ backgroundColor: dataRefund.formState === 'sending-data' ? '#949494' : '#3E9643', color: '#fff' }}>
                {dataRefund.formState === 'sending-data'
                  ? <>
                      <CircularProgress style={{ width: 20, height: 20, color: '#fff', marginRight: 10 }}  />
                      Enviando...
                    </>
                  : <>
                      Confirmar envio de dados
                    </>}
              </Button>
            </Grid>
            <Grid item xs={'auto'} style={{ marginTop: 30 }}>
              {dataRefund.formState !== 'sending-data' && (
                <Button
                  onClick={() => {
                    setDataRefund(prevState => {
                      return {
                        ...prevState,
                        confirmData: null,
                        formState: 'initial',
                      }
                    })
                  }}>
                  Voltar para formulário
                </Button>
              )}
            </Grid>
          </>)}
        </Grid>
      )}
    </Section>
  )
}

export default ConfirmData