import React from 'react'
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'
import IconInformation from '../components/IconInformation'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useFormContext } from 'react-hook-form'

const YourSelectionForRefund = () => {
  const { t } = useTranslation()
  const { getValues } = useFormContext()

  const getLabelTypeRefund = () => {
    const values = getValues()

    if (values.type === 'pix') {
      return 'Chave Pix'
    }

    return 'Dados bancarios'
  }
  
  return (
    <Section>
      <Grid container spacing={3}>
        <Grid 
          item
          xs={'auto'}
          style={{ display: 'flex', alignItems: 'center' }}>
          <IconInformation />
        </Grid>
        <Grid 
          style={{ display: 'flex', alignItems: 'center' }}
          item 
          xs>
          <Typography 
            style={{ lineHeight: 1.2 }} 
            dangerouslySetInnerHTML={{ __html: t('your_selection', { type: getLabelTypeRefund() }) }}
          />
        </Grid>
      </Grid>
    </Section>
  )
}

export default YourSelectionForRefund