import React from 'react'
import { useApp } from "../contexts/AppContext"
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'
import IconInformation from '../components/IconInformation'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const WhyWePayOut = () => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()
  const [openDetails, setOpenDetails] = React.useState(false)

  const handleClickOpen = () => {
    setOpenDetails(true)
  }

  const handleClose = () => {
    setOpenDetails(false)
  }

  return (
    <div>
      <Dialog
        open={openDetails}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">{t('who_is_wpo_title')}</DialogTitle>
        <DialogContent>
          {[1,2,3].map(index => (
            <DialogContentText key={index} id={`alert-dialog-slide-description-paragraph-${index}`}
              style={{ lineHeight: 1.2, marginBottom: 5 }} 
              dangerouslySetInnerHTML={{ __html: t(`how_is_wpo.${index}`) }}
            />
          ))}

          <Link href="https://wepayments.com.br/" hrefLang="pt-br" target="_blank">
            <DialogContentText id="alert-dialog-slide-description-site"
              style={{ lineHeight: 1.2, marginTop: 40, marginBottom: 5 }} 
              dangerouslySetInnerHTML={{ __html: t('site_wpo') }}
            />
          </Link>

          <Link href="https://ajuda.wepayout.com.br/" hrefLang="pt-br" target="_blank">
            <DialogContentText id="alert-dialog-slide-description-site"
              style={{ lineHeight: 1.2, marginBottom: 5 }} 
              dangerouslySetInnerHTML={{ __html: t('helpcenter_wpo') }}
            />
          </Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('who_is_wpo_close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Section style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <Grid container spacing={3}>
          <Grid 
            item
            xs={'auto'}
            style={{ display: 'flex', alignItems: 'center' }}>
            <IconInformation />
          </Grid>
          <Grid item xs>
            <Typography 
              style={{ lineHeight: 1.2, marginBottom: 5 }} 
              dangerouslySetInnerHTML={{ __html: t('responsible_wepayout') }}
              />
            <Typography 
              style={{ lineHeight: 1.2 }} 
              dangerouslySetInnerHTML={{ __html: t('read_more_about_processor', { secondary_color: dataRefund.data.merchant.secondary_color }) }}
              />
          </Grid>
        </Grid>
      </Section>
    </div>
  )
}

export default WhyWePayOut