import React from 'react'
import styled from 'styled-components'
import StepWizard from 'react-step-wizard'
import { useApp } from '../contexts/AppContext'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import Button from "@material-ui/core/Button"
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import WhyWePayOut from '../partials/WhyWePayOut'
import PaymentInfo from '../partials/PaymentInfo'
import DocumentRefund from '../partials/DocumentRefund'
import HelpDataBanking from '../partials/HelpDataBanking'
import ThisIsYourRefund from '../partials/ThisIsYourRefund'
import YourSelectionForRefund from '../partials/YourSelectionForRefund'

import TypeTransactionRefund from '../forms/TypeTransactionRefund'
import TypePixKey from '../forms/TypePixKey'
import ValuePixKey from '../forms/ValuePixKey'
import Bank from '../forms/Bank'
import TypeAccount from '../forms/TypeAccount'
import Account from '../forms/Account'
import AccountDigit from '../forms/AccountDigit'
import BranchAccount from '../forms/BranchAccount'
import ConfirmData from "../partials/ConfirmData"
import RefundStatus from "../partials/RefundStatus"
// import LayerAlert from "../components/LayerAlert";
import ShowError from "../partials/ShowError"

const Mobile = () => {
  const { setDataRefund, dataRefund } = useApp()
  const { getValues } = useFormContext()

  const handleUpdateStateWizard = value => {
    setDataRefund(current => {
      return {
        ...current,
        stateWizardMobile: value
      }
    })
  }

  const enablePix = () => {
    const values = getValues()
    return Boolean(values.type) && values.type === 'pix'
  }

  const enableDataBanking = () => {
    const values = getValues()
    return Boolean(values.type) && values.type === 'databanking'
  }

  if ([2,4].includes(dataRefund.data.status)) {
    return (
      <Hidden only={['md', 'xl', 'lg']}>
        <SpaceEmptyTop />
        <RefundStatus mobile={true} />
        <PaymentInfo />
      </Hidden>
    )
  }

  return (
    <Hidden only={['md', 'xl', 'lg']}>
      <StepWizard
        nav={<DoneStep />}
        isLazyMount={false}
        onStepChange={value => handleUpdateStateWizard(value)}
        initialStep={1}
        className="wizard-mobile-wrapper">
        <ScreenInitial />
        <ScreenSelectTypeRefund />
        {enablePix() && <ScreenTypePixKey />}
        {enableDataBanking() && <ScreenDataBanking />}
        <ScreenConfirm />
      </StepWizard>
    </Hidden>
  )
}

const ScreenConfirm = () => {
  return (
    <div className="wizard-mobile-wrapper">
      <SpaceEmptyTop />
      <ConfirmData mobile={true} />
      <SpaceEmpty />
    </div>
  )
}

const ScreenInitial = () => {
  const { dataRefund } = useApp()

  return (
    <div className="wizard-mobile-wrapper">
      <SpaceEmptyTop />
      {dataRefund.data.status === 5 && (
        <ShowError mobile={true} />
      )}
      <ThisIsYourRefund />
      <WhyWePayOut />
      <DocumentRefund />
      <PaymentInfo />
      <SpaceEmpty />
    </div>
  )
}

const ScreenSelectTypeRefund = () => {
  const { t } = useTranslation()

  return (
    <div className="wizard-mobile-wrapper">
      <SpaceEmptyTop />
      <Section title={t('type_refund_payment.title')}>
        <TypeTransactionRefund />
      </Section>
      <SpaceEmpty />
    </div>
  )
}

const ScreenDataBanking = () => {
  const { t } = useTranslation()

  return (
    <div className="wizard-mobile-wrapper">
      <SpaceEmptyTop />
      <YourSelectionForRefund />
      <Section title={t('bank.title')}>
        <Bank mobile={true} />
      </Section>
      <HelpDataBanking />
      <Section title={t('type_account.title')}>
        <TypeAccount />
      </Section>
      <DocumentRefund />
      <Section title={t('branch_account.title')}>
        <BranchAccount mobile={true} />
      </Section>
      <div style={{ display: 'flex' }}>
        <Section 
          style={{ flex: 1, marginRight: 4 }} 
          title={t('account.title')}>
          <Account mobile={true} />
        </Section>
        <Section 
          style={{ flex: 1, marginLeft: 3 }} 
          title={t('account_digit.title')}>
          <AccountDigit mobile={true} />
        </Section>
      </div>
      <SpaceEmpty />
    </div>
  )
}

const ScreenTypePixKey = () => {
  const { t } = useTranslation()
  const { getValues } = useFormContext()

  const enableValuePixKey = () => {
    const values = getValues()
    return Boolean(values.type_pix_key)
  }

  return (
    <div className="wizard-mobile-wrapper">
      <SpaceEmptyTop />
      <YourSelectionForRefund />
      <DocumentRefund />
      <Section title={t('type_pix_key.title')}>
        <TypePixKey />
      </Section>
      {enableValuePixKey() && (
        <Section title={t('value_pix_key.title')}>
          <ValuePixKey mobile={true} />
        </Section>
      )}
      <SpaceEmpty />
    </div>
  )
}

const DoneStep = props => {
  const { t } = useTranslation()
  const { dataRefund, setDataRefund } = useApp()
  const { getValues, formState } = useFormContext()

  const handlePressButton = () => {
    if ((props.totalSteps - 1) === props.currentStep) {
      document.querySelector('#submit').click()
    } else if (props.totalSteps === props.currentStep) {
      setDataRefund(prevState => {
        return {
          ...prevState,
          formState: 'sending-data',
        }
      })
    } else {
      props.nextStep()
      window.scrollTo(0,0)
    }
  }

  return (
    <>
      <ButtonStep
        fullWidth
        variant="contained"
        color="primary"
        className={props.currentStep === props.totalSteps ? 'last-step' : undefined}
        type={'button'}
        disabled={['validation', 'sending-data'].includes(dataRefund.formState)}
        onClick={() => handlePressButton()}>
        <Typography
          style={{ textTransform: 'uppercase' }}>
          {dataRefund.formState === 'validation'
            ? t('validating')
            : dataRefund.formState === 'sending-data'
              ? t('sending_data')
              : (props.currentStep === props.totalSteps ? t('send_data') : t('continue'))}
        </Typography>
      </ButtonStep>
      <div style={{ display: 'none' }}>
        <div id="last-step" onClick={props.lastStep}>back</div>
        <div id="back-step" onClick={props.previousStep}>back</div>
        <button type={'submit'} id="submit">simule submit</button>
      </div>
    </>
  )
}

const SpaceEmptyTop = styled.div`
  height: 11px;
`

const SpaceEmpty = styled.div`
  width: 100%;
  height: calc(72px + 15px);
`

const ButtonStep = styled(Button)`
  height: 72px;
  border-radius: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  border: 0;
  z-index: 99;
  cursor: pointer;

  span p {
    color: #fff !important;
  }
  
  &.last-step {
    background: #289519 !important;
  }
  
  &:disabled {
    background-color: #848484 !important;
  }
`

export default Mobile