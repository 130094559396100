import React from 'react'
import { useTranslation } from 'react-i18next'

import Section from '../components/Section'

import Typography from '@material-ui/core/Typography'
import {useApp} from "../contexts/AppContext";

const ThisIsYourRefund = () => {
  const { t } = useTranslation()
  const { dataRefund } = useApp()
  
  return (
    <Section>
      <Typography 
        style={{ lineHeight: 1.2, marginBottom: 10, fontWeight: 'bold' }} 
        dangerouslySetInnerHTML={{ __html: t('enunciated_refund') }} 
      />
      <Typography 
        style={{ lineHeight: 1.2 }} 
        dangerouslySetInnerHTML={{ __html: t('enunciated_refund_steps', { merchant_name: dataRefund.data.merchant.name, secondary_color: dataRefund.data.merchant.secondary_color }) }}
      />
    </Section>
  )
}

export default ThisIsYourRefund