import React from 'react'
import styled from 'styled-components'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useFormContext, Controller } from 'react-hook-form'

const TypeTransactionRefund = props => {
  const { title } = props
  const methods = useFormContext()

  return (
    <Wrapper>
      <FormControl error={Boolean(methods.errors.type)} component="fieldset">
        {title && <TitleForm className={Boolean(methods.errors.type) ? 'has-error' : undefined}>{title}</TitleForm>}

        <Controller
          defaultValue={null}
          name="type"
          control={methods.control}
          onChange={e => console.log(e)}
          render={({ onChange, onBlur, ...props }) => (
            <RadioGroup
              {...props}
              className="wrapper-radio-group"
              disabled={methods.formState.isSubmitting}
              onBlur={onBlur}
              onChange={e => {
                onChange(e)
                methods.trigger()
              }}
              aria-label="type">
              <FormControlLabel value="pix" control={<Radio />} label="Informar chave Pix" />
              <FormControlLabel value="databanking" control={<Radio />} label="Informar dados bancários" />
            </RadioGroup>
          )}
        />
        {Boolean(methods.errors.type) && <FormHelperText>{methods.errors.type.message}</FormHelperText>}

      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media(min-width: 960px) {
    margin-bottom: 30px;

    .wrapper-radio-group {
      flex-direction: row;

      > label {
        margin-right: 30px;
      }
    }
  }
`

const TitleForm = styled.h3`
  margin-top: 0;
  margin-bottom: 7px;

  &.has-error {
    color: #ff1744;
  }
`

export default TypeTransactionRefund