import React from 'react'

import Typography from '@material-ui/core/Typography'

const ShowInfo = props => {
  const { label, value } = props

  return (
    <div>
      <Typography style={{ textTransform: 'uppercase', fontSize: 12, lineHeight: 1, color: '#9a9a9a', marginBottom: 3 }}>{label}</Typography>
      <Typography style={{ lineHeight: 1.2 }}>{value}</Typography>
    </div>
  )
}

export default ShowInfo