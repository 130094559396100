import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { hotjar } from 'react-hotjar'
import { AppProvider } from './contexts/AppContext'
import { Integrations } from "@sentry/tracing"


import App from './App'

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  hotjar.initialize(2401391, 6)

  Sentry.init({
    dsn: "https://ee1d802e83fd4875b5623a619c094692@o229811.ingest.sentry.io/5818558",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

import './i18n'
import './styles/main.css'

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.querySelector('#root'),
)
