import React from 'react'
import styled from 'styled-components'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {useApp} from "../contexts/AppContext";

const TypePixKey = props => {
  const { title } = props
  const { t } = useTranslation()
  const methods = useFormContext()
  const { dataRefund } = useApp()

  return (
    <Wrapper>
      <FormControl error={Boolean(methods.errors.type_pix_key)} component="fieldset">
        {title && <TitleForm className={Boolean(methods.errors.type_pix_key) ? 'has-error' : undefined}>{title}</TitleForm>}

        <Controller
          defaultValue={null}
          name="type_pix_key"
          control={methods.control}
          onChange={e => console.log(e)}
          render={({ onChange, onBlur, ...props}) => (
            <RadioGroup
              {...props}
              className="wrapper-radio-group"
              disabled={methods.formState.isSubmitting}
              onBlur={() => {
                onBlur()
                methods.trigger()
              }}
              onChange={e => {
                onChange(e)
                methods.trigger()
              }}
              aria-label="type">
              <FormControlLabel value="tax_id" control={<Radio />} label={t('type_pix_key.tax_id')} />
              <FormControlLabel value="phone" control={<Radio />} label={t('type_pix_key.phone')} />
              <FormControlLabel value="email" control={<Radio />} label={t('type_pix_key.email')} />
              <FormControlLabel value="evp" control={<Radio />} label={t('type_pix_key.evp')} />
            </RadioGroup>
          )}
        />
        {Boolean(methods.errors.type_pix_key) && <FormHelperText>{methods.errors.type_pix_key.message}</FormHelperText>}
      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media(min-width: 960px) {
    margin-bottom: 30px;

    .wrapper-radio-group {
      flex-direction: row;

      > label {
        margin-right: 30px;
      }
    }
  }
`

const TitleForm = styled.h3`
  margin-top: 0;
  margin-bottom: 7px;

  &.has-error {
    color: #ff1744;
  }
`

export default TypePixKey