import { Container, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const Section = props => {
  const { title, styleTitle: style = {}, ...rest } = props

  return (
    <CardWrapper {...rest}>
      <Container>
        {Boolean(title) && <Title style={style}><Typography style={{ lineHeight: 1.2 }} className="title-text">{title}</Typography></Title>}

        {props.children}
      </Container>
    </CardWrapper>
  )
}

const Title = styled.div`
  margin-bottom: 17px;
  
  // title
  p { font-weight: bold; }

  @media(min-width: 960px) {
    padding: 22px;
    line-height: 1.2;
    background-color: #F7F7F7;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    margin-bottom: 30px;
  }
`

const CardWrapper = styled.div`
  background: #fff;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
  margin-bottom: 7px;
  padding: 17px 0;
  overflow: hidden;

  @media(min-width: 960px) {
    border-radius: 6px;
    padding: 30px;

    // container
    > div {
      padding: 0;
    }
  }
`

export default Section