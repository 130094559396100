import React from 'react'
import styled from 'styled-components'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'

import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useApp } from '../contexts/AppContext'

const ValuePixKey = props => {
  const { t } = useTranslation()
  const { title } = props
  const { dataRefund } = useApp()

  const methods = useFormContext()
  const { type_pix_key } = methods.getValues()

  const infos = {
    label: t('value_pix_key.placeholder'),
    tax_id: {
      enter_valid: t('value_pix_key.enter_tax_id_valid'),
      placeholder: 'Digite seu documento'
    },
    phone: {
      enter_valid: 'Digite um número de celular válido',
      placeholder: 'Digite seu número de celular ex: 41900000000'
    },
    email: {
      enter_valid: 'Digite um endereço de e-mail válido',
      placeholder: 'Digite seu endereço de e-mail'
    },
    evp: {
      enter_valid: 'Insira sua chave aleatória',
      placeholder: 'Digite sua chave aleatória'
    },
  }

  return (
    <Wrapper>
      <FormControl error={Boolean(methods.errors.value_pix_key)} fullWidth={true} component="fieldset">
        {title && <TitleForm className={Boolean(methods.errors.value_pix_key) ? 'has-error' : undefined}>{title}</TitleForm>}

        <Controller
          name="value_pix_key" 
          control={methods.control}
          defaultValue=""
          render={({ ...props }) => (
            <TextField
              {...props}
              label={infos.label}
              disabled={methods.formState.isSubmitting}
              error={Boolean(methods.errors.value_pix_key)}
              variant={props.mobile ? 'filled' : 'outlined'}
              id="value_pix_key"
              type={['phone'].includes(type_pix_key) ? 'number' : 'text'}
              helperText={methods.errors.value_pix_key ? (methods.errors.value_pix_key.message) : (infos[type_pix_key] ? infos[type_pix_key].enter_valid : '')}
              placeholder={infos[type_pix_key] ? infos[type_pix_key].placeholder : ''}
              autoComplete="off"
              {...(type_pix_key === 'tax_id' && {
                value: dataRefund.data.beneficiary.document.value,
                disabled: true
              })}
              {...(type_pix_key === 'phone' && {
                InputProps: {
                  startAdornment: <InputAdornment position="start">+55</InputAdornment>
                }
              })}
            />
          )}
        />

      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  
  @media(min-width: 960px) {
    margin-bottom: 30px;    
  }
`

const TitleForm = styled.h3`
  margin-top: 0;
  margin-bottom: 20px;
`

export default ValuePixKey